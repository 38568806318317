<template>
  <div class="adddevice-contaner">
    <!-- 设备信息 -->
    <div class="d-msg">
      <p class="f14 c6 no-change">
        <span class="title">公司名称：</span>
        <span>
          <el-select
            v-model="ruleForm.companyId"
            placeholder="请选择公司"
            @change="changeComp"
          >
            <el-option
              v-for="item in company"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </span>
      </p>
      <p class="f14 c6 no-change">
        <span class="title">公司联系人：</span>
        <span>{{ checkCompMsg.contacts || "-" }}</span>
      </p>
      <p class="f14 c6 no-change">
        <span class="title">公司联系方式:</span>
        <span>{{ checkCompMsg.contactsPhone || "-" }}</span>
      </p>
      <p class="f14 c6 no-change">
        <span class="title">系统编号:</span>
        <span>{{ systemNumber }}</span>
      </p>
      <p class="f14 c6 no-change mb30">
        <span class="title">二维码编号:</span>
        <span class="cr">*用户需要在移动端扫码绑定</span>
      </p>
      <el-form
        label-width="107px"
        :model="ruleForm"
        :status-icon="false"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <!-- 编号 -->
        <el-form-item label="编号:" required prop="deviceNumber">
          <el-input
            type="number"
            maxlength="20"
            v-model="ruleForm.deviceNumber"
            placeholder="请输入"
            autocomplete="off"
            oninput="value=value.replace(/^(0+)|[^\d]+/g, '').slice(0, 20)"
          ></el-input>
        </el-form-item>
        <!-- 编号结束 -->
        <!-- 设备类型 -->
        <el-form-item
          class="mb20"
          label="设备类型:"
          required
          prop="deviceTypeId"
        >
          <el-row class="check" type="flex" align="middle">
            <!-- 一级分类 -->
            <!-- <el-select
                  v-model="ruleForm.deviceTypeId"
                  placeholder="请选择设备类型"
                >
                  <el-option
                    v-for="item in comps"
                    :key="item.deviceTypeId"
                    :label="item.typeName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select> -->
            <!-- 一级分类结束 -->

            <el-cascader
              placeholder="请选择设备类型"
              :options="options1"
              :props="props1"
              :value="posValue1"
              @change="posHandle1"
            ></el-cascader>
          </el-row>
        </el-form-item>
        <!-- 设备类型结束 -->
        <!-- 设备品牌 -->
        <el-form-item class="mb20" label="设备品牌:" required prop="brandId">
          <el-row class="check" type="flex" align="middle">
            <el-select v-model="ruleForm.brandId" placeholder="请选择设备品牌">
              <el-option
                v-for="item in brandData"
                :key="item.id"
                :label="item.brandName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-row>
        </el-form-item>
        <!-- 设备品牌结束 -->
        <!-- 设备控制系统 -->
        <el-form-item
          class="mb20"
          label="设备控制系统:"
          required
          prop="controlId"
        >
          <el-row class="check" type="flex" align="middle">
            <el-select
              v-model="ruleForm.controlId"
              placeholder="请选择设备控制系统"
            >
              <el-option
                v-for="item in controls"
                :key="item.id"
                :label="item.controlName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-row>
        </el-form-item>
        <!-- 设备控制系统结束 -->
        <!-- 设备型号 -->
        <el-form-item
          class="mb20"
          label="设备型号:"
          required
          prop="deviceModel"
        >
          <el-row class="check" type="flex" align="middle">
            <el-input
              type="text"
              maxlength="20"
              v-model="ruleForm.deviceModel"
              placeholder="请输入设备型号"
              autocomplete="off"
            ></el-input>
          </el-row>
        </el-form-item>
        <!-- 设备型号结束 -->
        <!-- 日期 -->
        <el-form-item label="出厂日期:" required prop="productionDate">
          <el-input
            type="date"
            v-model="ruleForm.productionDate"
            placeholder="请选择出厂日期"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 日期结束 -->
        <!-- 地区 -->
        <el-form-item class="mb20" label="地区:" required prop="provinceCode">
          <el-row class="check" type="flex" align="middle">
            <!-- <el-select v-model="ruleForm.pos" placeholder="请选择设备位置">
                  <el-option
                    v-for="item in posData"
                    :key="item.id"
                    :label="item.value"
                    :value="item.value"
                  >
                    <span style="float: left; color: #8492a6; font-size: 13px">{{
                      item.value
                    }}</span>
                  </el-option>
                </el-select> -->
            <el-cascader
              placeholder="请选择设备位置"
              :options="options"
              :props="props"
              :value="posValue"
              @change="posHandle"
            ></el-cascader>
          </el-row>
        </el-form-item>
        <!-- 地区结束 -->
        <!-- 详细地址: -->
        <el-form-item
          class="mb20"
          label="详细地址:"
          required
          prop="detailedAddress"
        >
          <el-input
            type="textarea"
            maxlength="300"
            v-model="ruleForm.detailedAddress"
            placeholder="请输入设备详细地址"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 详细地址:结束 -->
        <!-- 设备状态: -->
        <el-form-item class="mb20" label="设备状态:" required prop="status">
          <el-row class="check" type="flex" align="middle">
            <el-select v-model="ruleForm.status" placeholder="请选择设备状态">
              <el-option
                v-for="item in stateData"
                :key="item.status"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-row>
        </el-form-item>
        <!-- 设备状态:结束 -->
        <!-- 设备状态: -->
        <!-- <el-form-item
          class="mb20"
          label="设备车间:"
          required
          prop="workShopName"
        >
          <el-row class="check" type="flex" align="middle">
            <el-select
              v-model="ruleForm.workShopName"
              placeholder="请选择车间名称"
            >
              <el-option
                v-for="item in workShopData"
                :key="item.status"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-row>
        </el-form-item> -->
        <!-- 设备状态:结束 -->
        <!-- 提交开始 -->
        <el-form-item>
          <el-button class="btn cf f16" @click="submitForm('ruleForm')"
            >确认</el-button
          >
          <el-button @click="back">取消</el-button>
        </el-form-item>
        <!-- 提交结束 -->
      </el-form>
    </div>
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangAdddevice",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    var validateId = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入编号"));
      }
      callback();
    };
    var validateType = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入设备类型"));
      }
      callback();
    };
    var validateBrand = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入设备品牌"));
      }
      callback();
    };
    var validateModel = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入设备型号"));
      }
      callback();
    };
    var validateDate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入日期"));
      }
      callback();
    };
    var validatePos = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入地区"));
      }
      callback();
    };
    var validateAddress = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入地址"));
      }
      callback();
    };
    var validateState = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入状态"));
      }
      callback();
    };
    var validateWorkShop = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择设备车间"));
      }
      callback();
    };
    return {
      ruleForm: {
        companyId: "", //公司id
        deviceNumber: "",
        deviceTypeId: "", //所选设备类型
        brandId: "", //所选设备品牌
        deviceModel: "", //所选设备型号
        productionDate: "", //日期
        pos: "", //地区
        detailedAddress: "", //地址
        status: "", //状态
        provinceCode: "", //省code
        cityCode: "", //市code
        areaCode: "", //区code
        controlId: "", //所属控制系统
        workShopName: "", //车间名称
      },
      rules: {
        deviceNumber: [{ validator: validateId, trigger: ["blur", "change"] }],
        deviceTypeId: [
          { validator: validateType, trigger: ["blur", "change"] },
        ],
        brandId: [{ validator: validateBrand, trigger: ["blur", "change"] }],
        deviceModel: [
          { validator: validateModel, trigger: ["blur", "change"] },
        ],
        controlId: [
          {
            required: true,
            message: "请选择设备控制系统",
            trigger: ["blur", "change"],
          },
        ],
        productionDate: [
          { validator: validateDate, trigger: ["blur", "change"] },
        ],
        pos: [{ validator: validatePos, trigger: ["blur", "change"] }],
        detailedAddress: [
          { validator: validateAddress, trigger: ["blur", "change"] },
        ],
        status: [{ validator: validateState, trigger: ["blur", "change"] }],
        // workShopName: [
        //   { validator: validateWorkShop, trigger: ["blur", "change"] },
        // ],
        provinceCode: [
          {
            required: true,
            message: "请选择地区",
            trigger: ["blur", "change"],
          },
        ],
      },
      checkCompMsg: [], //所选中公司的信息
      company: [], //所有公司
      comps: [], //设备所有类型
      controls: [], //设备控制系统
      brandData: [], //设备所有品牌
      modelData: [], //设备所有型号
      posData: [], //设备所有地区
      stateData: [], //设备所有状态
      workShopData: [], //设备车间
      compData: [],
      flag: true, //防止重复点击
      // 地区选择器
      props: { value: "id", label: "fullname", children: "areaTrees" },
      options: [],
      posValue: [],

      systemNumber: 0, //系统编号  自动生成

      // 设备类型选择器
      props1: { value: "id", label: "typeName", children: "childrenList" },
      options1: [],
      posValue1: [],
      choseType: [],
    };
  },

  mounted() {},

  created() {
    // 获取所有公司
    this.getAllComp();

    // 查询全部设备类型
    this.getAllType();

    // 查询全部设备品牌
    this.getAllBrand();

    // 查询设备控制系统
    this.getAllControl();

    // 状态分类统计
    this.getAllState();

    // 查询全部地址管理
    this.getAllAdd();

    //查询省市区
    this.getArea();

    // 设备-获取系统编号
    this.getAddSys();
  },

  methods: {
    // 设备-获取系统编号
    getAddSys() {
      this.$API
        .getAddSys()
        .then((res) => {
          if (res.code == 1) {
            this.systemNumber = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询设备控制系统
    getAllControl() {
      this.$API
        .getAllControl()
        .then((res) => {
          if (res.code == 1) {
            this.controls = res.data;
            // console.log(this.controls, "controls");
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 设备类型改变
    posHandle1(a) {
      console.log(a);
      this.choseType = a;
      this.ruleForm.deviceTypeId = a.join(",");
      // console.log(this.ruleForm.deviceTypeId);
    },

    // 省市区改变
    posHandle(a) {
      console.log("posValue", this.posValue);
      console.log("a", a);
      this.ruleForm.provinceCode = a[0];
      this.ruleForm.cityCode = a[1];
      this.ruleForm.areaCode = a[2];
      // 选中省市区对应名称的获取
      let selectProvince = {};
      let selectCity = {};
      let selectArea = {};
      this.options.forEach((item) => {
        if (item.id == this.ruleForm.provinceCode) {
          selectProvince = item;
        }
      });
      selectProvince.areaTrees.forEach((item) => {
        if (item.id == this.ruleForm.cityCode) {
          selectCity = item;
        }
      });
      selectCity.areaTrees.forEach((item) => {
        if (item.id == this.ruleForm.areaCode) {
          selectArea = item;
        }
      });
      this.ruleForm.provinceName = selectProvince.fullname;
      this.ruleForm.cityName = selectCity.fullname;
      this.ruleForm.areaName = selectArea.fullname;
    },

    //查询省市区
    getArea() {
      this.$API
        .getArea()
        .then((res) => {
          if (res.code == 1) {
            // this.options = res.data;
            this.options = res.data.map((item) => {
              // 第一级，市
              // item.areaTrees 第二级，区
              item.areaTrees = item.areaTrees.map((secondItem) => {
                // secondItem
                secondItem.areaTrees = secondItem.areaTrees.map((thirdItem) => {
                  delete thirdItem.areaTrees;
                  return thirdItem;
                });
                return secondItem;
              });

              return item;
            });
            console.log(this.options);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 改变公司
    changeComp(val) {
      console.log("0000000000000000");
      console.log(this.ruleForm.companyId);
      // console.log(val);
      let that = this;
      if (this.company.length) {
        this.company.forEach((item) => {
          if (item.id == that.ruleForm.companyId) {
            that.checkCompMsg = item;
          }
        });
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!this.flag) {
          return;
        }
        if (valid) {
          console.log("提交了");

          // 对地址的处理
          // console.log("pos", this.posData);
          // if (this.posData.length) {
          //   this.posData.forEach((item) => {
          //     if (this.ruleForm.pos == item.value) {
          //       let { provinceCode, cityCode, areaCode } = item;
          //       this.ruleForm = {
          //         ...this.ruleForm,
          //         ...{ provinceCode, cityCode, areaCode },
          //       };
          //     }
          //   });
          // }
          //对公司信息的处理
          let { id, contacts, contactsPhone } = this.checkCompMsg;
          this.ruleForm.companyId = id;
          this.ruleForm.customerContact = contacts;
          this.ruleForm.customerPhone = contactsPhone;
          this.ruleForm.systemNumber = this.systemNumber;
          (this.ruleForm.deviceTypeParentId = this.choseType[0]),
            (this.ruleForm.deviceTypeId = this.choseType[1]);
          this.flag = false;

          // 编辑接口
          let params = { ...this.ruleForm };
          // console.log("params", params);
          // return;

          this.$API.addAdd(params).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "新增成功",
                type: "success",
                duration: 1000,
              });
              setTimeout(() => {
                this.flag = true;
                this.$router.go(-1);
              }, 800);
            } else {
              this.flag = true;
              this.$message.info(res.msg);
            }
          });
          // this.$router.push("/device");
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 返回
    back() {
      this.$router.go(-1);
    },

    // 查询全部设备类型
    getAllType() {
      this.$API
        .getAllType()
        .then((res) => {
          if (res.code == 1) {
            // this.options1 = res.data;

            // this.options = res.data;
            this.options1 = res.data.map((item) => {
              // 第一级，市
              // item.areaTrees 第二级，区
              item.childrenList = item.childrenList.map((secondItem) => {
                // secondItem
                // secondItem.childrenList = secondItem.childrenList.map(
                //   (thirdItem) => {
                //     delete thirdItem.childrenList;
                //     return thirdItem;
                //   }
                // );
                delete secondItem.childrenList;
                return secondItem;
              });

              return item;
            });

            console.log(this.options1, "options1");
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查询全部设备品牌
    getAllBrand() {
      this.$API
        .getAllBrand()
        .then((res) => {
          if (res.code == 1) {
            this.brandData = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 状态分类统计
    getAllState() {
      this.$API
        .getAllState()
        .then((res) => {
          // this.stateData = res.result;
          // console.log(this.stateData);
          if (res.code == 1) {
            this.stateData = res.data;
            // console.log(this.stateData);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取所有公司
    getAllComp() {
      this.$API
        .getAllComp()
        .then((res) => {
          if (res.code == 1) {
            this.company = res.data;
          } else {
            this.$message.info(res.msg);
          }
          // console.log(this.compData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询全部地址管理
    getAllAdd() {
      this.$API
        .getAllAdd()
        .then((res) => {
          if (res.code == 1) {
            res.data.records.forEach((item) => {
              this.posData.push({
                // provinceName: item.provinceName,
                value: `${item.provinceName} ${item.cityName} ${item.areaName}`,
                id: item.id,
                provinceCode: item.provinceCode,
                cityCode: item.cityCode,
                areaCode: item.areaCode,
              });
              // console.log(this.posData, "posdata");
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.adddevice-contaner {
  width: 100%;
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  //   设备信息
  .d-msg {
    margin-top: 20px;
    margin-left: 40px;
    .no-change {
      margin-bottom: 41px;
    }
    .title {
      display: inline-block;
      width: 107px;
      text-align: left;
    }
    .btn {
      margin-left: 0px;
      margin-right: 12px;
      background: linear-gradient(90deg, #ed1b23 0%, #f15f64 100%);
      color: #ffffff;
    }
  }
  .el-input__inner {
    width: 240px;
    height: 40px;
  }
  .el-form-item__label {
    text-align: left;
  }
  .el-textarea__inner {
    width: 400px;
    height: 120px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
  }
}
</style>
